<template lang="html">
  <div id="contact-page">

    <section class="container form-section" data-aos="fade-in">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="box-form">
            <h1 class="title">Contáctanos</h1>

            <b-form @submit="onSubmit">
              <b-form-group>
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder="NOMBRE COMPLETO"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-6 pr-md-2">
                  <b-form-group>
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder="CORREO ELECTRÓNICO"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6 pl-md-2">
                  <b-form-group>
                    <b-form-input
                      v-model="form.phone"
                      type="text"
                      placeholder="TELÉFONO"
                      minlength="10"
                      maxlength="10"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12">
                  <b-form-group>
                    <b-form-select v-model="form.subject" required>
                      <b-form-select-option :value="null">ASUNTO</b-form-select-option>
                      <b-form-select-option value="1">Opción 1</b-form-select-option>
                      <b-form-select-option value="2">Opción 2</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-12">
                  <b-form-group>
                    <b-form-textarea
                      v-model="form.msg"
                      placeholder="MENSAJE"
                      rows="6"
                      max-rows="6"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-12 text-center">
                  <b-button type="submit" class="t-400 btn-send">Enviar</b-button>
                </div>
              </div>

            </b-form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    }
  },
}
</script>
