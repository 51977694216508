<template lang="html">
  <footer id="footer" v-if="$route.name != 'accessPage' && $route.name != 'accountPage'">
		<div class="container oversized-container">
			<div class="row">
				<div class="col-12 col-menu">
					<router-link to="/sobre-nosotros">Sobre nosotros</router-link>
					<router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
					<router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
					<router-link to="/contacto">Contacto</router-link>
				</div>

				<div class="col-12 col-info">
					<h6>Todos los derechos reservados Bexer {{ currentYear }}</h6>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data(){
		return{
			currentYear: 0,
		}
	},

	beforeMount(){
		this.currentYear = new Date().getFullYear()
	}
}
</script>
