<template lang="html">
  <div id="account-home-page">
    <div class="box-today">
      <h3 class="mb-2 today">Hoy, 13 de Mayo</h3>
      <h5 class="mb-4 hour">Hola, son las 12:34 hrs.</h5>

      <div class="row">
        <div class="col-12 col-sm-6">
          <h5 class="f-w-600 txt-blue">Agenda <span class="ml-1 badge badge-blue">3</span></h5>
        </div>

        <div class="col-12 col-sm-6 text-center text-sm-right mt-3 mt-sm-0">
          <router-link class="btn btn-sm btn-s2 btn-gray" to="/usuario/agenda">Ir a mi agenda</router-link>
        </div>
      </div>
    </div>

    <div class="box-schedule">
      <swiper class="swiper" :options="eventsOptions">
        <swiper-slide v-for="(e, eInx) in 6" :key="'eInx-'+eInx">
          <div class="box-event">
            <h6 class="mb-3 hour"><i class="fal fa-clock mr-1"></i> A las 11:20 hrs</h6>

            <h6 class="mb-3 title">Nombre de evento programado ejemplo</h6>

            <a class="btn btn-xs btn-s2 btn-bluelgt" to="">Confirmar</a>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="box-wr-notifications">
      <div class="row px-1">
        <div class="col-lg-6">
          <h5 class="mb-3 f-w-600 d-inline-block txt-blue">Acuerdos <span class="ml-1 badge badge-blue">3</span></h5>

          <b-form-select class="float-right filter-s1" v-model="filterAcuerdos">
            <b-form-select-option :value="null">Filtrar por</b-form-select-option>
            <b-form-select-option value="a">Option A</b-form-select-option>
            <b-form-select-option value="b">Option B</b-form-select-option>
          </b-form-select>

          <div class="box-notifications">
            <div class="box-notify" v-for="(a, aInx) in 6">
              <!-- <a class="btn-close"><i class="fal fa-times"></i></a> -->

              <h5 class="mb-2 date"><i class="fas fa-exclamation-circle ic"></i> Hace 5 minutos</h5>
              <h6 class="mb-2 title">Título de notificacion ejemplo</h6>

              <div class="box-descr">
                <p>Descripción de notificación ejemplo o ajuste corto sencillo</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4 mt-lg-0">
          <h5 class="mb-3 f-w-600 d-inline-block txt-blue">Alertas <span class="ml-1 badge badge-blue">3</span></h5>

          <b-form-select class="float-right filter-s1" v-model="filterAlertas">
            <b-form-select-option :value="null">Filtrar por</b-form-select-option>
            <b-form-select-option value="a">Option A</b-form-select-option>
            <b-form-select-option value="b">Option B</b-form-select-option>
          </b-form-select>

          <div class="box-notifications">
            <div class="box-notify" v-for="(a, aInx) in 6">
              <!-- <a class="btn-close"><i class="fal fa-times"></i></a> -->

              <h5 class="mb-2 date"><i class="fas fa-exclamation-circle ic"></i> Hace 5 minutos</h5>
              <h6 class="mb-2 title">Título de notificacion ejemplo</h6>

              <div class="box-descr">
                <p>Descripción de notificación ejemplo o ajuste corto sencillo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      filterAcuerdos: null,
      filterAlertas: null,

      // == Carousel ==
      eventsOptions: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1500: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          575: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },

  beforeMount() {
    this.$parent.pageName = 'Inicio';
  }
}
</script>
