<template lang="html">
  <div id="account-support">

    <div class="box-top">
      <div class="col-lg-7 col-title">
        <h2 class="title-s4"><i class="fas fa-info-circle"></i> PREGUNTAS FRECUENTES</h2>
      </div>

      <div class="col-12">
        <hr />
      </div>
    </div>

    <div class="col-12 box-content">
      <div class="box-faq" v-for="(qa, iInx) in 10" :key="'iInx-'+iInx">
        <h6 class="q">Pregunta numero {{ iInx+1 }}</h6>
        <div class="a">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
            Dolor sit amet excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  methods: {

  },

  beforeMount() {
    this.$parent.pageName = 'Ayuda';
  },
}
</script>
