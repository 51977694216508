<template lang="html">
  <div id="account-profile-page">

    <div class="box-form">
      <div class="row">
        <!-- Tu información básica -->
        <div class="col-12">
          <h5 class="mb-2 f-w-700">Tu información básica</h5>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Nombre o Razón social">
            <b-form-input
              v-model="form.razonsocial"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Teléfono">
            <b-form-input
              v-model="form.phone"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Calle o dirección">
            <b-form-input
              v-model="form.direccion"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-3">
          <b-form-group class="custom-group-s3 blue" label="Num. Exterior">
            <b-form-input
              v-model="form.numext"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-3">
          <b-form-group class="custom-group-s3 blue" label="Num. Interior">
            <b-form-input
              v-model="form.numint"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Colonia">
            <b-form-input
              v-model="form.colonia"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-3">
          <b-form-group class="custom-group-s3 blue" label="Código postal">
            <b-form-input
              v-model="form.cp"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Estado">
            <b-form-input
              v-model="form.estado"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Ciudad">
            <b-form-input
              v-model="form.ciudad"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <!--  -->

        <!-- Sobre tu profesión -->
        <div class="col-12 mt-3">
          <hr class="mt-0" />
          <h5 class="mb-2 f-w-700">Sobre tu profesión</h5>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Profesión">
            <b-form-input
              v-model="form.profesion"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Área de interés">
            <b-form-input
              v-model="form.areainteres"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Nivel de jurisdicción">
            <b-form-input
              v-model="form.jurisdiccion"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Estado de interes">
            <b-form-input
              v-model="form.estadoInteres"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="¿Cómo te enteraste de nosotros?">
            <b-form-input
              v-model="form.enteraste"
              disabled
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <!--  -->

        <!-- Tu cuenta para acceder -->
        <div class="col-12 mt-3">
          <hr class="mt-0" />
          <h5 class="mb-2 f-w-700">Tu cuenta para acceder</h5>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Correo electrónico">
            <b-form-input
              v-model="form.email"
              disabled
              type="email"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Contraseña">
            <b-form-input
              v-model="form.password"
              disabled
              type="password"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s3 blue" label="Confirmar contraseña">
            <b-form-input
              v-model="form.passwordcon"
              disabled
              type="password"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 pt-2 text-right">
          <router-link class="btn btn-s1 w-100" to="/usuario/editar-perfil">Editar mis datos</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
      }
    }
  },

  beforeMount() {
    this.$parent.pageName = 'Ver mi perfil';
  }
}
</script>
