<template lang="html">
  <div id="account-record">

    <div class="box-top">
      <div class="col-lg-7 col-title">
        <h2 class="title-s4"><i class="fas fa-folder-open"></i> ADMINISTRAR CARPETAS</h2>
      </div>

      <div class="col-lg-5 col-actions">
        <b-form class="form-st1" @submit="onSubmit">
          <b-form-group class="custom-group-s3">
            <b-form-input
              v-model="searchForm.keywords"
              type="text"
              placeholder="Nombre carpeta..."
              required
            ></b-form-input>
            <b-button type="submit" class="btn-send"><i class="far fa-search"></i></b-button>
          </b-form-group>
        </b-form>
      </div>

      <div class="col-12">
        <hr />
      </div>
    </div>

    <div class="box-content">
      <div class="col-md-6 col-xl-4 col-folder" v-for="(e, eIdx) in exp" :key="'eIdx-'+eIdx">
        <router-link class="box-folder" to="/usuario/expedientes/1">
          <p class="p-icon">
            <i class="fas fa-folder-open"></i>
          </p>

          <div class="box-name">
            <h5 class="name">{{ e.name }}</h5>
          </div>

          <div class="box-bottom">
            <span>Asuntos registrados</span>
            <span class="n">{{ e.n }}</span>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      exp: [
        { name: 'Civil fuero', n: '38' },
        { name: 'Colegiados', n: '37' },
        { name: 'Distrito', n: '39' },
        { name: 'Distrito penal', n: '17' },
        { name: 'Familiar fuero', n: '28' },
        { name: 'Foraneos', n: '32' },
        { name: 'Mercantil fuero', n: '4' },
        { name: 'Oral Mercantil fuero', n: '14' },
        { name: 'Sala civiles', n: '1' },
        { name: 'Sala penales', n: '5' },
        { name: 'Tlajomulco', n: '17' },
      ],

      searchForm: {
        keywords: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.$parent.pageName = 'Expedientes';
  },
}
</script>
