<template lang="html">
  <header id="header" v-bind:class="{ 'alter' : isNotHomepage == true }" v-if="$route.name != 'accessPage' && $route.name != 'accountPage'">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="fal fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- <b-navbar-nav>
              <b-nav-item href="#">Link</b-nav-item>
            </b-navbar-nav> -->

            <b-navbar-nav class="ml-auto">
              <b-nav-item class="nav-simple" to="/registrarse">Crear cuenta</b-nav-item>
              <b-nav-item class="nav-button" to="/login">Ingresar</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
      isNotHomepage : false
    }
  },

  methods: {
    isHomePage(){
      if(this.$route.name == 'homePage'){
        this.isNotHomepage = false;
      }else{
        this.isNotHomepage = true;
      }
    },
  },

  mounted(){
    this.isHomePage();
  },

  watch:{
    '$route' (to, from){
      this.isHomePage();
    }
  }
}
</script>
