<template lang="html">
  <div id="account-search">

    <b-form class="mb-4 box-top" @submit="onSubmit">
      <div class="col-12 col-title">
        <h2 class="title-s4"><i class="fas fa-users"></i> ACTORES Y/O DEMANDADOS</h2>
        <hr />
      </div>

      <div class="col-sm-4 col-md-5 text-center text-sm-right"><h5>Juzgado</h5></div>
      <div class="col-sm-8 col-md-7">
        <b-form-group class="mb-0 custom-group-s3 border-r-0">
          <b-form-select required v-model="searchForm.juzgados">
            <b-form-select-option :value="null">Todos los juzgados Estatales, Federales y Salas</b-form-select-option>
            <b-form-select-option value="a">Opción 1</b-form-select-option>
            <b-form-select-option value="b">Opción 2</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-12"><hr /></div>

      <div class="col-sm-4 col-md-5 text-center text-sm-right"><h5>Parte</h5></div>
      <div class="col-sm-8 col-md-7">
        <b-form-group class="mb-0 custom-group-s3 border-r-0">
          <b-form-input
            v-model="searchForm.keywords"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12"><hr /></div>

      <div class="col-12 col-lg-7 offset-lg-5 text-center text-lg-left">
        <b-button type="submit" class="btn-s2 btn-blue border-r-0">Consultar</b-button>
      </div>
    </b-form>

    <div class="col-12">
      <div class="box-tables-container">
        <div class="box-table">
          <div class="table-responsive-md">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Columna</th>
                  <th scope="col">Columna</th>
                  <th scope="col">Columna</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, cInx) in 20" :key="'cInx-'+cInx">
                  <td>A20</td>
                  <td>305/2018</td>
                  <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip erehenderit in voluptate velit deserunt mollit anim id est laborum.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        juzgados: null,
        keywords: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.$parent.pageName = 'Búsqueda';
  },
}
</script>
