<template lang="html">
  <div id="account-table-page">

    <div class="box-form">
      <b-form class="form-st1" @submit="onSubmit">
        <b-form-group class="custom-group-s3">
          <b-form-input
            v-model="searchForm.keywords"
            type="text"
            placeholder="Buscar en Acuerdos"
            required
          ></b-form-input>
          <b-button type="submit" class="btn-send"><i class="far fa-search"></i></b-button>
        </b-form-group>
      </b-form>

      <div class="box-info">
        <div class="col-12 px-0">
          <h5 class="mb-2 f-w-600 txt-blue">Boletín de hoy</h5>
        </div>

        <div class="col-today">
          <h4 class="f-w-600">Hoy, 7 de Mayo del 2023</h4>
        </div>

        <span class="sep"></span>

        <div class="col-found">
          <h6><strong>52</strong> Expedientes encontrados</h6>
        </div>
      </div>
    </div>

    <div class="box-table">
      <div class="table-responsive-md">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Nombre de expediente</th>
              <th scope="col">Número de caso</th>
              <th scope="col">Clasificación</th>
              <th scope="col">Descripción</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, cInx) in 20" :key="'cInx-'+cInx">
              <th scope="row" class="col-name">Nombre de expediente</th>
              <td>Caso 369/999 II</td>
              <td class="col-classification">Civil sumario hipotecario - Simon moreno Rodriguez VS Salvador Alcala Zermeño</td>
              <td class="col-descr">Se recibe oficio del registro público de la propiedad</td>
              <td class="col-btns">
                <a class="btn btn-s2 btn-xs btn-bluelgt no-ic">Ver</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        keywords: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.$parent.pageName = 'Acuerdos';
  }
}
</script>
