<template lang="html">
  <div id="user-account">

    <section class="container-fluid main-section">
      <div class="row">

        <!-- Sidebar -->
        <div class="col-lg col-sidebar">
          <div class="bg-menu" v-bind:class="{ 'active' : showMenu }" @click="showMenu = false"></div>
          <a class="btn-toggle-sidebar" v-bind:class="{ 'active' : showMenu }" @click="showMenu = !showMenu"></a>

          <div class="box-sidebar" v-bind:class="{ 'active' : showMenu }">
            <div class="box-logo">
              <router-link to="/usuario">
                <img src="public/images/logo-b.svg" alt="Bexer">
              </router-link>
            </div>

            <div class="box-plan-info">
              <h6>Mi plan actual</h6>
              <h5>Plan Intermedio</h5>
              <p class="text-center">
                <router-link class="t-150 btn-plan" to="/usuario/planes">Cambiar plan</router-link>
              </p>
            </div>

            <div class="box-menu">
              <div class="accordion" role="tablist">
                <hr class="sep-s1" />

                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/acuerdos" class="link-item"><i class="ic ic-agrmt"></i> Acuerdos</router-link>
                  </b-card-header>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/agenda" class="link-item"><i class="ic ic-sched"></i> Agenda</router-link>
                  </b-card-header>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/alertas" class="link-item"><i class="ic ic-alerts"></i> Alertas</router-link>
                  </b-card-header>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/busqueda" class="link-item"><i class="ic ic-search"></i> Búsquedas</router-link>
                  </b-card-header>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/expedientes" class="link-item"><i class="ic ic-files"></i> Expedientes</router-link>
                  </b-card-header>
                </b-card>

                <hr class="sep-s1" />

                <b-card no-body class="w-submenus">
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="" class="link-item" v-b-toggle.accordion-1><i class="ic ic-mgmt"></i> Administración</router-link>
                  </b-card-header>

                  <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <router-link block to="/usuario/ver-perfil" class="link-item"><i class="ic ic-user"></i> Ver mi perfil</router-link>
                      <router-link block to="/usuario/editar-perfil" class="link-item"><i class="ic ic-uedit"></i> Editar mi perfil</router-link>
                      <!-- <router-link block to="" class="link-item"><i class="ic ic-payments"></i> Métodos de pago</router-link> -->
                      <router-link block to="" class="link-item"><i class="ic ic-queries"></i> Historial de consultas</router-link>
                      <router-link block to="" class="link-item"><i class="ic ic-calendar"></i> Historial de renovaciones</router-link>
                      <router-link block target="_blank" to="/terminos-y-condiciones" class="link-item"><i class="ic ic-bexer"></i> Términos y condiciones</router-link>
                      <router-link block target="_blank" to="/aviso-de-privacidad" class="link-item"><i class="ic ic-bexer"></i> Aviso de privacidad</router-link>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <hr class="sep-s1" />

                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/usuario/ayuda" class="link-item"><i class="ic ic-help"></i> Ayuda</router-link>
                  </b-card-header>
                </b-card>

                <hr class="sep-s1" />

                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="/contacto" class="link-item"><i class="ic ic-contact"></i> Contacto</router-link>
                  </b-card-header>
                </b-card>

                <hr class="sep-s1" />

                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <router-link block to="" class="link-item"><i class="ic ic-msg"></i> Comunicados</router-link>
                  </b-card-header>
                </b-card>

                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a block class="link-item"><i class="ic ic-logout"></i> Cerrar sesión</a>
                  </b-card-header>
                </b-card>

              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Page -->
        <div class="col-lg col-page-content">
          <div class="t-250 bg-basic-menu" v-bind:class="{ 'active' : showExtramenu }" @click="showExtramenu = false"></div>
          <div class="box-basic-menu">
            <div class="account-container">
              <div class="row">
                <div class="col-5 col-sm-6 col-page">
                  <h5>{{ pageName }}</h5>
                </div>

                <div class="col-7 col-sm-6 col-user-info">
                  <div class="t-150 _box" @click="showExtramenu = !showExtramenu">
                    <div class="box-text">
                      <h6 class="name">Jesús Torres Amezcua</h6>
                      <h6 class="role"><i class="fas fa-star icon"></i> Administrador</h6>
                    </div>

                    <div class="placed-backg box-picture" v-bind:style="{ backgroundImage: 'url(public/images/pages/user/photo.jpg)' }"></div>
                  </div>

                  <div class="t-250 box-account" v-bind:class="{ 'active' : showExtramenu }">
                    <h5 class="greeting">Hola</h5>
                    <h4 class="name">Jesus Torres Amezcua</h4>

                    <div class="box-user-account">
                      <div class="placed-backg box-picture" v-bind:style="{ backgroundImage: 'url(public/images/pages/user/photo.jpg)' }"></div>

                      <div class="box-text">
                        <h6 class="role">Administrador <i class="fas fa-star icon"></i></h6>
                        <h6 class="plan">Plan intermedio</h6>
                        <a class="t-150 btn-plan">Cambiar plan</a>
                      </div>
                    </div>

                    <ul class="menu">
                      <li>
                        <router-link to=""><i class="ic ic-user"></i> Mi cuenta</router-link>
                      </li>
                      <li>
                        <a href=""><i class="ic ic-logout"></i> Cerrar sesión</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-page-content">
            <div class="account-container">
              <router-view></router-view>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      pageName: null,

      showMenu : false,
      showExtramenu: false,
    }
  },

  watch: {
    '$route' (to, from){
      this.showMenu = false;
    }
  }
}
</script>
