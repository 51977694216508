<template lang="html">
  <div id="access-page">

    <section class="container main-section">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-form">
          <div class="col-12 box-welcome">
            <h2>Recuperar mi contraseña</h2>
          </div>

          <div class="col-12 box-form">
            <b-form @submit="onSubmit">
              <div class="box-top">
                <img class="logo" src="public/images/logo.svg" alt="Bexer">
              </div>

              <div class="row">
                <b-form-group class="custom-group-s2 user-icon col-12" label="CORREO ELECTRÓNICO">
                  <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
                </b-form-group>
              </div>

              <div class="box-actions">
                <router-link class="link-ext" to="/login">Si recuerdo mi contraseña</router-link>
              </div>

              <div class="box-buttons">
                <b-button type="submit" class="btn-s1">Iniciar sesión</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showPass: false,

      form: {
        name: null,
        password: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    }
  },
}
</script>
