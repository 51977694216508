<template lang="html">
  <div id="account-table-page">

    <div class="box-form">
      <b-form class="form-st1" @submit="onSubmit">
        <b-form-group class="custom-group-s3">
          <b-form-input
            v-model="searchForm.keywords"
            type="text"
            placeholder="Buscar en Alertas"
            required
          ></b-form-input>
          <b-button type="submit" class="btn-send"><i class="far fa-search"></i></b-button>
        </b-form-group>
      </b-form>

      <div class="box-info">
        <div class="col-today">
          <h4 class="f-w-600">Hoy, 7 de Mayo del 2023</h4>
        </div>

        <span class="sep"></span>

        <div class="col-found">
          <h6><strong>52</strong> Alertas encontradas</h6>
        </div>

        <div class="ml-auto col-button">
          <a class="btn-alert" v-b-modal.modal-create-alert>Crear alerta</a>
        </div>
      </div>
    </div>

    <div class="box-table">
      <div class="table-responsive-md">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Estado/Tribunal</th>
              <th scope="col">Juzgado</th>
              <th scope="col">Expediente</th>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, cInx) in 20" :key="'cInx-'+cInx">
              <th class="col-name">Nombre del tribunal X</th>
              <td>Caso 369/999 II</td>
              <td>R820 II</td>
              <td>Nombre va aquí</td>
              <td class="col-email">email@correo.com</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal modal-class="modal-s1" id="modal-create-alert" title="Crear alerta" hide-footer centered>
      <b-form @submit="onSubmitAlert">
        <b-form-group class="custom-group-s3 blue" label="Estado">
          <b-form-select required v-model="formAlert.estado">
            <b-form-select-option :value="null" disabled>Selecciona un estado</b-form-select-option>
            <b-form-select-option value="a">Jalisco</b-form-select-option>
            <b-form-select-option value="b">Ciudad de México</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group class="custom-group-s3 blue" label="Juzgado">
          <b-form-select required v-model="formAlert.juzgado">
            <b-form-select-option :value="null" disabled>Selecciona un juzgado</b-form-select-option>
            <b-form-select-option value="a">Juzgado 1</b-form-select-option>
            <b-form-select-option value="b">Juzgado 2</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group class="custom-group-s3 blue" label="Expediente">
          <b-form-input
            v-model="formAlert.expediente"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-group-s3 blue" label="Nombre">
          <b-form-input
            v-model="formAlert.nombre"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <p class="pt-1 text-right">
          <b-button type="submit" class="btn-s1 w-100">Crear alerta</b-button>
        </p>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        keywords: null,
      },

      formAlert: {
        estado: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    onSubmitAlert(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.$parent.pageName = 'Alertas';
  },
}
</script>
