import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home/index.vue').default },
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},
	    { path: '/sobre-nosotros', component: require(page+'about-us/index.vue').default, meta:{title:"Sobre nosotros"}},

      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de Privacidad"} },
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"} },

      { path: '/login', name: 'accessPage', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', name: 'accessPage', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/usuario', component: require(page+'user-account/index.vue').default, meta:{title:"Mi Cuenta"},
        children: [
          {
            path: '/',
            name: 'accountPage',
            component: require(page+'user-account/home.vue').default,
            meta:{ title: 'Mi cuenta' }
          },
          {
            path: 'planes',
            name: 'accountPage',
            component: require(page+'user-account/planes.vue').default,
            meta:{ title: 'Planes' }
          },
          {
            path: 'acuerdos',
            name: 'accountPage',
            component: require(page+'user-account/acuerdos.vue').default,
            meta:{ title: 'Acuerdos' }
          },
          {
            path: 'agenda',
            name: 'accountPage',
            component: require(page+'user-account/agenda.vue').default,
            meta:{ title: 'Agenda' }
          },
          {
            path: 'alertas',
            name: 'accountPage',
            component: require(page+'user-account/alertas.vue').default,
            meta:{ title: 'Alertas' }
          },
          {
            path: 'expedientes',
            name: 'accountPage',
            component: require(page+'user-account/expedientes.vue').default,
            meta:{ title: 'Expedientes' }
          },
          {
            path: 'expedientes/:id',
            name: 'accountPage',
            component: require(page+'user-account/expedientes-detalle.vue').default,
            meta:{ title: 'Expedientes' }
          },
          {
            path: 'busqueda',
            name: 'accountPage',
            component: require(page+'user-account/busqueda.vue').default,
            meta:{ title: 'Búsqueda' }
          },
          {
            path: 'ayuda',
            name: 'accountPage',
            component: require(page+'user-account/ayuda.vue').default,
            meta:{ title: 'Ayuda' }
          },

          {
            path: 'ver-perfil',
            name: 'accountPage',
            component: require(page+'user-account/account/ver-perfil.vue').default,
            meta:{ title: 'Ver perfil' }
          },
          {
            path: 'editar-perfil',
            name: 'accountPage',
            component: require(page+'user-account/account/editar-perfil.vue').default,
            meta:{ title: 'Editar perfil' }
          },
        ]
      },

      { path: '/usuarios', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
        children: [
          {
            path: '/',
            component: require(page+'usuario-cuenta/mis-datos.vue').default,
            meta:{ title: 'Mis datos' }
          },
          {
            path: 'contrasena',
            component: require(page+'usuario-cuenta/contrasena.vue').default,
            meta:{ title: 'Cambiar contraseña' }
          },

          // {
          //   path: 'nueva-cotizacion',
          //   component: require(page+'usuario-cuenta/nueva-cotizacion.vue').default,
          //   meta:{ title: 'Mis cotizaciones' }
          // },
          // {
          //   path: 'mis-cotizaciones',
          //   component: require(page+'usuario-cuenta/mis-cotizaciones.vue').default,
          //   meta:{ title: 'Mis facturas' }
          // }
        ]
      }

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Bexer", filter: (title)=>{ return title+" - Bexer"; } }
);

// export {routes};
export default MyRouter;
