<template lang="html">
  <div id="account-plans">

    <div class="box-top">
      <div class="col-lg-7 col-title">
        <h2 class="title-s4"><i class="fas fa-info-circle"></i> TODOS LOS PLANES</h2>
      </div>

      <div class="col-12">
        <hr />
      </div>
    </div>

    <b-form class="form" @submit="register">
      <!-- Step 1 -->
      <div class="col-12 box-content" v-if="step == 1">
        <div class="box-plan-time-s1" data-aos="fade-down" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom">
          <div class="box-bubble">
            <div class="bubble" v-bind:class="{ 'active' : plan == 'm' }" @click="plan = 'm'">Mensual</div>
            <!-- <div class="bubble" v-bind:class="{ 'active' : plan == 'a' }" @click="plan = 'a'">Anual</div> -->
          </div>
        </div>

        <div class="row">
          <!-- Mensual -->
          <div class="col-12 col-plans" v-if="plan == 'm'">
            <swiper class="swiper" :options="plansOptions">
              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer" style="opacity: 0;">gratis</span>
                    </p>

                    <h4 class="plan-name">Gratis</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>Anuncios</p>
                      <p>5 Búsquedas</p>
                      <p>5 Expedientes</p>
                    </div>

                    <h5 class="price">MXN $0.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[0])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <a @click="planSelected = 1">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Individual</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $98.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[1])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <a @click="planSelected = 2">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Pro<span class="extra"><span>MÁS VENDIDO</span></span></h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>3</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $178.00</h5>
                    <!-- <h5 class="price-b-descount">$279 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[2])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <a @click="planSelected = 3">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="200" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Premium<span class="extra"><span>Mejor valor</span></span> </h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>6</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                      <p>Compartir eventos</p>
                      <p>Gestión de usuarios y permisos</p>
                      <p>Compartir búsquedas, acuerdos y alertas</p>
                    </div>

                    <h5 class="price">MXN $288.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[3])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <a @click="planSelected = 4">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!--  -->

          <!-- Anual -->
          <div class="col-12 col-plans" v-if="plan == 'a'">
            <swiper class="swiper" :options="plansOptions">
              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <a class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Individual</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,188.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[0])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <a @click="planSelected = 5">Suscríbete</a>
                    </div>
                  </a>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="center-bottom">
                  <a class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Pro<span class="extra"><span>MÁS VENDIDO</span></span></h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>3</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,548.00</h5>
                    <!-- <h5 class="price-b-descount">$279 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[1])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <a @click="planSelected = 6">Suscríbete</a>
                    </div>
                  </a>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="200" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Premium<span class="extra"><span>Mejor valor</span></span> </h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>6</strong> Usuarios</p>
                      <p style="line-height: 1.1;">Gestión de usuarios y permisos</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,908.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[2])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <a @click="planSelected = 7">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="300" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Estudiante</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $588.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[3])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <a @click="planSelected = 8">Suscríbete</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!--  -->

        </div>
      </div>
      <!--  -->

      <!-- Step 2 -->
      <div class="col-12 box-content" v-if="step == 2">
        <div class="row pt-1 pb-3 justify-content-center">
          <div class="col-md-6 my-2 box-payment-opts-s1">
            <div class="box-payment" v-bind:class="{ 'active' : paymentOpt == 'card' }" @click="paymentOpt = 'card'">
              <h6>EN LÍNEA DE FORMA SEGURA.</h6>
              <h6>CON DÉBITO O CRÉDITO</h6>

              <p class="mt-3">
                <img src="public/images/shared/payments.svg">
              </p>
            </div>
          </div>

          <div class="col-md-6 my-2 box-payment-opts-s1">
            <div class="box-payment" v-bind:class="{ 'active' : paymentOpt == 'paypal' }" @click="paymentOpt = 'paypal'">
              <h6>EN LÍNEA A TRAVES DE</h6>
              <h6>PAYPAL</h6>

              <p class="mt-3">
                <img src="public/images/shared/paypal.svg">
              </p>
            </div>
          </div>

          <div class="col-md-6 my-2 box-payment-opts-s1">
            <div class="box-payment" v-bind:class="{ 'active' : paymentOpt == 'oxxo' }" @click="paymentOpt = 'oxxo'">
              <h6>FICHA PARA DEPÓSITO O<br />TRANSFERENCIA BANCARIA.</h6>

              <p class="mt-3">
                <img src="public/images/shared/oxxo.svg">
              </p>
            </div>
          </div>

          <div class="col-md-6 my-2 box-payment-opts-s1">
            <div class="box-payment" v-bind:class="{ 'active' : paymentOpt == 'cash' }" @click="paymentOpt = 'cash'">
              <h6>FICHA PARA DEPÓSITO O<br />TRANSFERENCIA BANCARIA.</h6>

              <p class="mt-3">
                <img src="public/images/shared/cash.svg">
              </p>
            </div>
          </div>

          <!-- Tarjeta de crédito -->
          <div class="text-center box-selected-payment-s1" v-if="paymentOpt == 'card'">
            <h6 class="mb-3 f-w-700">Pago con Tarjeta de Débito o Crédito</h6>

            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 my-1 pl-lg-0">
                  <b-form-input
                    type="text"
                    v-model="form.cardName"
                    required
                    placeholder="Nombre en la tarjeta">
                  </b-form-input>
                </div>

                <div class="col-lg-6 my-1 pr-lg-0">
                  <b-form-input
                    type="password"
                    v-model="form.cardNumber"
                    required
                    placeholder="Número de tarjeta">
                  </b-form-input>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Paypal -->
          <div class="text-center box-selected-payment-s1" v-if="paymentOpt == 'paypal'">
            <h6 class="mb-3 f-w-700">Pago con Paypal</h6>

            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 my-1 pl-lg-0">
                  <b-form-input
                    type="email"
                    v-model="form.paypalEmail"
                    required
                    placeholder="Correo electrónico">
                  </b-form-input>
                </div>

                <div class="col-lg-6 my-1 pr-lg-0">
                  <b-form-input
                    type="password"
                    v-model="form.paypalCon"
                    required
                    placeholder="Contraseña">
                  </b-form-input>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Oxxo -->
          <div class="text-center box-selected-payment-s1" v-if="paymentOpt == 'oxxo'">
            <p>
              <img class="img-payment" src="public/images/shared/oxxo.svg">
            </p>

            <p class="mt-3">
              <strong>Sucursal:</strong> Oxxo </strong><br />
              <strong>Cuenta bancaria:</strong> 32145688025<br />
              <strong>CLABE interbancaria:</strong> 9876543210258961
            </p>
          </div>
          <!--  -->

          <!-- Transferencia -->
          <div class="text-center box-selected-payment-s1" v-if="paymentOpt == 'cash'">
            <p>
              <img class="img-payment" src="public/images/shared/santander.png">
            </p>

            <p class="mt-3">
              <strong>Banco:</strong> Santander </strong><br />
              <strong>Beneficiario:</strong> Bexer<br />
              <strong>Cuenta bancaria:</strong> 9876543210<br />
              <strong>CLABE interbancaria:</strong> 0123456789123
            </p>
          </div>
          <!--  -->
        </div>

        <div class="col-12">
          <hr />
        </div>

        <div class="col-lg-5 mx-auto mb-4">
          <b-form-group class="custom-group-s1" label="Código promocional">
            <b-form-input type="text"
                          v-model="form.codigoProm"
                          placeholder="">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 mb-3 text-center">
          <div class="d-inline-block">
            <b-form-checkbox
              v-model="form.tos"
              name="usertype"
              value="1"
              unchecked-value=""
              required
            >
              Acepto los términos y condiciones
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <!--  -->

      <div class="col-12">
        <b-form-group class="mt-3 text-center text-lg-right">
          <a type="submit" class="my-1 mr-sm-2 btn btn-s2 btn-outline-blueDark border-r-0 no-ic" v-if="step > 1" @click="step = 1">Anterior</a>
          <b-button type="submit" class="my-1 btn-s2 btn-blueDark border-r-0 no-ic" v-if="step < 2">Siguiente</b-button>
          <b-button type="submit" class="my-1 btn-s2 btn-blueDark border-r-0 no-ic" v-if="step == 2"><i class="fas fa-folder-upload"></i> Cambiar plan</b-button>
        </b-form-group>
      </div>
    </b-form>

    <!-- Modal Términos y condiciones -->
    <b-modal modal-class="modal-s1" ref="modal-benefit" :title="'Términos y condiciones ('+modalInfo.name+')'" size="lg" hide-footer centered>
      <div v-html="modalInfo.terms"></div>
    </b-modal>
    <!--  -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,

      plan: 'm',
      planSelected: null,

      paymentOpt: null,

      form: {
        cardName: null,
        cardNumber: null,
      },
      formErrors: [],

      modalInfo: {
        name: null,
      },

      plans: [
        { name: 'Gratis', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod ut labore et dolore magna aliqua.<br />Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Individual', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br />Ut enim ad minim veniam, exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Pro', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />Ut enim ad veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Premium', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempore magna aliqua. <br />Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
      ],

      // == Carousels options ==
      plansOptions: {
        slidesPerView: 4,
        spaceBetween: 20,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 20
          },
        }
      },
      // == ==
    }
  },

  methods: {
    openTermsModal(plan) {
      this.modalInfo = plan; // Se necesita el "nombre" y el "contenido" de los términos y condiciones del plan específico

      this.$refs['modal-benefit'].show();
    },

    register(evt){
      evt.preventDefault();
      this.formErrors = [];
      document.documentElement.scrollTop = 200;

      if(this.step == 2){
        alert('Plan modificado exitosamente');
      }
      if(this.step == 1){
        this.step = 2;
      }
    }
  },

  beforeMount() {
    this.$parent.pageName = 'Planes';
  },
}
</script>
