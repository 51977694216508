<template lang="html">
  <div id="account-profile-page">

    <div class="box-form">
      <b-form @submit="onSubmit">
        <div class="row">

          <!-- Tu información básica -->
          <div class="col-12">
            <h5 class="mb-2 f-w-700">Tu información básica</h5>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Nombre o Razón social">
              <b-form-input
                v-model="form.razonsocial"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Teléfono">
              <b-form-input
                v-model="form.phone"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Calle o dirección">
              <b-form-input
                v-model="form.direccion"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-3">
            <b-form-group class="custom-group-s3 blue" label="Num. Exterior">
              <b-form-input
                v-model="form.numext"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-3">
            <b-form-group class="custom-group-s3 blue" label="Num. Interior">
              <b-form-input
                v-model="form.numint"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Colonia">
              <b-form-input
                v-model="form.colonia"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-3">
            <b-form-group class="custom-group-s3 blue" label="Código postal">
              <b-form-input
                v-model="form.cp"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Estado">
              <b-form-select required v-model="form.estado">
                <b-form-select-option :value="null" disabled>Selecciona un estado</b-form-select-option>
                <b-form-select-option value="a">Jalisco</b-form-select-option>
                <b-form-select-option value="b">Ciudad de México</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Ciudad">
              <b-form-select required v-model="form.ciudad">
                <b-form-select-option :value="null" disabled>Selecciona un ciudad</b-form-select-option>
                <b-form-select-option value="a">Guadalajara</b-form-select-option>
                <b-form-select-option value="b">Zapopan</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <!--  -->

          <!-- Sobre tu profesión -->
          <div class="col-12 mt-3">
            <hr class="mt-0" />
            <h5 class="mb-2 f-w-700">Sobre tu profesión</h5>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Profesión">
              <b-form-select required v-model="form.profesion">
                <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                <b-form-select-option value="a">Abogado Civil</b-form-select-option>
                <b-form-select-option value="b">Otros</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Área de interés">
              <b-form-select required v-model="form.areainteres">
                <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                <b-form-select-option value="a">Jurídica</b-form-select-option>
                <b-form-select-option value="b">Otros</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Nivel de jurisdicción">
              <b-form-select required v-model="form.jurisdiccion">
                <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                <b-form-select-option value="a">Estatal</b-form-select-option>
                <b-form-select-option value="b">Otros</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Estado de interes">
              <b-form-select required v-model="form.estadoInteres">
                <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                <b-form-select-option value="a">Jalisco</b-form-select-option>
                <b-form-select-option value="b">Otros</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="¿Cómo te enteraste de nosotros?">
              <b-form-select required v-model="form.enteraste">
                <b-form-select-option :value="null" disabled>Selecciona una opción</b-form-select-option>
                <b-form-select-option value="a">Opción 1</b-form-select-option>
                <b-form-select-option value="b">Otros</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <!--  -->

          <!-- Tu cuenta para acceder -->
          <div class="col-12 mt-3">
            <hr class="mt-0" />
            <h5 class="mb-2 f-w-700">Tu cuenta para acceder</h5>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Correo electrónico">
              <b-form-input
                v-model="form.email"
                type="email"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Contraseña">
              <b-form-input
                v-model="form.password"
                type="password"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6">
            <b-form-group class="custom-group-s3 blue" label="Confirmar contraseña">
              <b-form-input
                v-model="form.passwordcon"
                type="password"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 pt-2 text-right">
            <b-button type="submit" class="btn-s1 w-100">Guardar datos</b-button>
          </div>

        </div>
      </b-form>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },

  beforeMount() {
    this.$parent.pageName = 'Editar mi perfil';
  }
}
</script>
