<template lang="html">
  <div id="account-record">

    <div class="box-top">
      <div class="col-md-6 col-title">
        <h2 class="mb-1 title-s4"><i class="fas fa-folder-open"></i> Civil fuero</h2>
        <p>Hay 38 asuntos contenidos en esta carpeta</p>
      </div>

      <div class="col-md-6 text-center text-md-right col-actions">
        <a class="btn btn-sm btn-s2 btn-outline-gray no-ic f-w-500 mr-2"><i class="far fa-print mr-1"></i> Imprimir</a>
        <a class="btn btn-sm btn-s2 btn-outline-gray no-ic f-w-500"><i class="far fa-file-pdf"></i> Generar PDF</a>
      </div>

      <div class="col-12">
        <hr />
      </div>
    </div>

    <div class="col-12">
      <div class="box-tables-container" v-for="(x, tInx) in 2" :key="'tInx-'+tInx">
        <h5 class="mb-2 f-w-400 text-right">Primero Civil</h5>
        <div class="box-table">
          <div class="table-responsive-md">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Expediente</th>
                  <th scope="col">Partes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, cInx) in 20" :key="'cInx-'+cInx">
                  <td class="col-n-exp">305/2018</td>
                  <td>Mario davalos aguirre fregoso vs sucesión a bienes de Ricardo lopez hidalgo, susan preciado coronado de lopez hidalgo, ricardo lopez hidalgo preciado, juan lopez hidalgo preciado, jaime hidalgo preciado</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  methods: {
  },

  beforeMount() {
    this.$parent.pageName = 'Expedientes';
  },
}
</script>
