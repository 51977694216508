<template lang="html">
  <div id="home-page">

    <router-link class="t-150 btn-fixed" to="/registrarse">App movil gratis</router-link>

    <!-- Banners -->
    <section class="top-section" data-aos="fade-in">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-md-7 col-text">
            <h3>
              La <strong>aplicación</strong> de<br />
              confianza para consultar<br />
              <strong>procesos legales</strong> en<br />
              México...
            </h3>

            <p class="mt-3">
              <a class="t-150 btn-app mr-1" target="_blank" href="https://www.apple.com/app-store/">
                <img src="public/images/shared/app-store.svg" alt="App Store">
              </a>

              <a class="t-150 btn-app" target="_blank" href="https://play.google.com/">
                <img src="public/images/shared/google-play.svg" alt="Google Play">
              </a>
            </p>
          </div>

          <div class="col-md-5 col-phone">
            <img src="public/images/pages/home/phone-top.png">
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- <section class="benefits-section">
      <div class="container oversized-container">
        <h2 class="mb-3 section-title-s1" data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">Beneficios de BEXER</h2>

        <div class="row">
          <div class="col-lg col-side-benefit col-left">
            <div class="col-6 col-lg-12 col-benefit col-l" data-aos="fade-right" @click="openTermsModal(0)">
              <p>
                <img src="public/images/pages/home/benefit-1.png">
              </p>
              <h5>Automatización de carga laboral</h5>
            </div>

            <div class="col-6 col-lg-12 mt-lg-2 col-benefit col-l" data-aos="fade-right" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom" @click="openTermsModal(1)">
              <p>
                <img src="public/images/pages/home/benefit-2.png">
              </p>
              <h5>Aprovechar el tiempo en una estrategia de alto nivel</h5>
            </div>
          </div>

          <div class="col-lg col-phone" data-aos="fade-up" data-aos-delay="200" data-aos-anchor-placement="center-bottom">
            <img src="public/images/pages/home/phone-2.png">
          </div>

          <div class="col-lg col-side-benefit col-right">
            <div class="col-6 col-lg-12 col-benefit col-r" data-aos="fade-left" @click="openTermsModal(2)">
              <p>
                <img src="public/images/pages/home/benefit-3.png">
              </p>
              <h5>Reducción de costos operacionales</h5>
            </div>

            <div class="col-6 col-lg-12 mt-lg-2 col-benefit col-r" data-aos="fade-left" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom" @click="openTermsModal(3)">
              <p>
                <img src="public/images/pages/home/benefit-4.png">
              </p>
              <h5>Eliminación de interrupciones no planificadas</h5>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Beneficios -->
    <!-- <section class="benefits-section-v2">
      <div class="container oversized-container">
        <h2 class="mb-1 section-title-s1" data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">Beneficios de BEXER<small class="mark">®</small></h2>

        <div class="row">
          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up">
            <p class="p-image">
              <img src="public/images/pages/home/benefit-v2-1.png">
            </p>

            <h6 class="title">Consulta historial judicial / laboral / penal de cualquier persona física y moral.</h6>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="200">
            <p class="p-image">
              <img src="public/images/pages/home/benefit-v2-2.png">
            </p>

            <h6 class="title">Programa audiencias, reuniones, pendientes y actividades con tu agenda digital BEXER.</h6>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="400">
            <p class="p-image">
              <img src="public/images/pages/home/benefit-v2-3.png">
            </p>

            <h6 class="title">Personaliza tus carpetas para administrar tus expedientes y alertas.</h6>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="600">
            <p class="p-image">
              <img src="public/images/pages/home/benefit-v2-4.png">
            </p>

            <h6 class="title">¡Despreocúpate, BEXER te notifica a tu teléfono!</h6>
          </div>
        </div>
      </div>
    </section> -->
    <!--  -->

    <section class="benefits-section-v3">
      <div class="container oversized-container">
        <h2 class="mb-2 section-title-s1" data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">Beneficios de BEXER<small class="mark">®</small></h2>

        <div class="row">
          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up">
            <div class="box-benefit">
              <div class="box-info">
                <div class="box-image">
                  <div class="square b1"></div>
                </div>

                <div class="box-text">
                  <h6>Consulta</h6>

                  <p>
                    Historial judicial, laboral y penal de cualquier persona física y moral.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="100">
            <div class="box-benefit">
              <div class="box-info">
                <div class="box-image">
                  <div class="square b2"></div>
                </div>

                <div class="box-text">
                  <h6>Programa</h6>

                  <p>
                    Audiencias, reuniones, pendientes y actividades con tu agenda digital BEXER®.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="200">
            <div class="box-benefit">
              <div class="box-info">
                <div class="box-image">
                  <div class="square b3"></div>
                </div>

                <div class="box-text">
                  <h6>Personaliza</h6>

                  <p>
                    Carpetas para administrar tus expedientes y alertas.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-3 col-benefit" data-aos="fade-up" data-aos-delay="300">
            <div class="box-benefit">
              <div class="box-info">
                <div class="box-image">
                  <div class="square b4"></div>
                </div>

                <div class="box-text">
                  <h6>Notificaciones</h6>

                  <p>
                    ¡Despreocúpate, BEXER te notifica a tu teléfono!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Planes -->
    <section class="plans-section">
      <div class="bg" data-aos="fade-right" data-aos-anchor-placement="center-bottom"></div>

      <div class="container">
        <h2 class="section-title-s1" data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">Elige la membresía que necesitas<br />para tu firma legal</h2>

        <div class="box-plan-time-s1" data-aos="fade-down" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom">
          <div class="box-bubble">
            <div class="bubble" v-bind:class="{ 'active' : plan == 'm' }" @click="plan = 'm'; planSelected = null">Mensual</div>
            <!-- <div class="bubble" v-bind:class="{ 'active' : plan == 'a' }" @click="plan = 'a'; planSelected = null">Anual</div> -->
          </div>
        </div>

        <div class="row">
          <!-- Mensual -->
          <div class="col-12 col-plans" v-if="plan == 'm'">
            <swiper class="swiper" :options="plansOptions">
              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer" style="opacity: 0;">gratis</span>
                    </p>

                    <h4 class="plan-name">Gratis</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>Anuncios</p>
                      <p>5 Búsquedas</p>
                      <p>5 Expedientes</p>
                    </div>

                    <h5 class="free">GRATIS</h5>
                    <h5 class="price">MXN $0.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[0])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=1">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="position-relative mb-2">
                      <span class="for">Para pequeños despachos</span>
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Individual</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>2</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="free" style="opacity: 0;">0.00</h5>
                    <h5 class="price">MXN $98.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[1])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=2">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="position-relative mb-2">
                      <span class="for">Para grandes firmas</span>
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Pro<span class="extra"><span>MÁS VENDIDO</span></span></h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>3</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="free" style="opacity: 0;">0.00</h5>
                    <h5 class="price">MXN $178.00</h5>
                    <!-- <h5 class="price-b-descount">$279 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[2])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=3">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="200" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="position-relative mb-2">
                      <span class="for">Para corporativos</span>
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Premium<span class="extra"><span>Mejor valor</span></span> </h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>6</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Personalizar carpetas</p>
                      <p>Personalizar búsquedas</p>
                      <p>Personalizar alertas</p>
                      <p>Notificaciones</p>
                      <p>Compartir eventos</p>
                      <p>Gestión de usuarios y permisos</p>
                      <p>Compartir búsquedas, acuerdos y alertas</p>
                    </div>

                    <h5 class="free" style="opacity: 0;">0.00</h5>
                    <h5 class="price">MXN $288.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[3])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=4">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <!-- <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="300" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Estudiante</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $49.00</h5>
                    <h5 class="price-b-descount">$299 MXN</h5>

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[3])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=4">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide> -->

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!--  -->

          <!-- Anual -->
          <div class="col-12 col-plans" v-if="plan == 'a'">
            <swiper class="swiper" :options="plansOptions">
              <swiper-slide>
                <!-- <div class="col-sm-6 col-lg-4 col-xl-3 col-plan-sample-1"> -->
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <a class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Individual</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,188.00</h5>
                    <!-- <h5 class="price-b-descount">$249 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[0])">Términos y condiciones</a>
                    </p>

                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=5">Suscríbete</router-link>
                    </div>
                  </a>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="center-bottom">
                  <a class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Pro<span class="extra"><span>MÁS VENDIDO</span></span></h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>3</strong> Usuarios</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,548.00</h5>
                    <!-- <h5 class="price-b-descount">$279 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[1])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=6">Suscríbete</router-link>
                    </div>
                  </a>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="200" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Premium<span class="extra"><span>Mejor valor</span></span> </h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app-pc.svg" alt="App Computadora">
                    </div>

                    <div class="box-benefits">
                      <p>App y Computadora</p>
                      <p><strong>6</strong> Usuarios</p>
                      <p style="line-height: 1.1;">Gestión de usuarios y permisos</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $1,908.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[2])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=7">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 px-0 col-plan-sample-1"  data-aos="fade-up" data-aos-delay="300" data-aos-anchor-placement="center-bottom">
                  <div class="box-plan">
                    <p class="mb-2">
                      <span class="extra-offer">1 mes gratis</span>
                    </p>

                    <h4 class="plan-name">Estudiante</h4>

                    <div class="box-app-computer">
                      <img src="public/images/pages/home/app.svg" alt="App">
                    </div>

                    <div class="box-benefits">
                      <p>App</p>
                      <p><strong>1</strong> Usuario</p>
                      <p>
                        <i class="far fa-infinity ic-infinity"></i>
                        <i class="fas fa-info-circle ic-info" v-b-tooltip.hover title="Acceso ilimitado a búsquedas, expedientes, alertas y acuerdos"></i>
                      </p>
                      <p>Notificaciones</p>
                    </div>

                    <h5 class="price">MXN $588.00</h5>
                    <!-- <h5 class="price-b-descount">$299 MXN</h5> -->

                    <p class="text-center">
                      <a class="link-terms" @click="openTermsModal(plans[3])">Términos y condiciones</a>
                    </p>


                    <div class="box-subscribe">
                      <router-link to="/registrarse?p=8">Suscríbete</router-link>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <!--  -->

        </div>
      </div>
    </section>
    <!--  -->

    <!-- Funciones -->
    <section class="steps-section" data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top-bottom">
      <div class="bg" data-aos="fade-right" data-aos-anchor-placement="center-bottom" data-aos-delay="350"></div>

      <div class="container">
        <h2 class="mb-3 section-title-s1" data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">Algunas funciones de BEXER<small class="mark">®</small></h2>

        <swiper class="swiper" :options="stepsOptions">
          <swiper-slide>
            <div class="box-step">
              <img src="public/images/pages/home/step-1.png">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="box-step">
              <img src="public/images/pages/home/step-2.png">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="box-step">
              <img src="public/images/pages/home/step-3.png">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="box-step">
              <img src="public/images/pages/home/step-4.png">
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <!--  -->

    <!-- Modal Términos y condiciones -->
    <b-modal modal-class="modal-s1" ref="modal-benefit" :title="'Términos y condiciones ('+modalInfo.name+')'" size="lg" hide-footer centered>
      <div v-html="modalInfo.terms"></div>
    </b-modal>
    <!--  -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      plan: 'm',
      planSelected: null,

      modalInfo: {
        name: null,
      },

      plans: [
        { name: 'Gratis', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod ut labore et dolore magna aliqua.<br />Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Individual', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br />Ut enim ad minim veniam, exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Pro', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />Ut enim ad veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
        { name: 'Premium', terms: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempore magna aliqua. <br />Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>' },
      ],

      // == Carousels options ==
      plansOptions: {
        slidesPerView: 4,
        spaceBetween: 20,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 20
          },
        }
      },

      stepsOptions: {
        loop: true,
        speed: 700,
        effect: 'fade',

        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      }
      // == ==
    }
  },

  methods: {
    openTermsModal(plan) {
      this.modalInfo = plan; // Se necesita el "nombre" y el "contenido" de los términos y condiciones del plan específico

      this.$refs['modal-benefit'].show();
    }
  },

  mounted(){
  }
}
</script>
